@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap');

@layer base {
  body {
    @apply font-[Raleway];
  }
  li {
    @apply px-4;
    @apply cursor-pointer;
  }
}

.App {
  background-color: #0a192f;
}

.content-div {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 250px;
}

.content-div:hover {
  background-image: linear-gradient(
    to right,
    rgba(71, 129, 255, 0.8),
    hsla(235, 67%, 31%, 0.813)
  ) !important;
}

h1[data-original-text]:hover::before {
  content: attr(data-original-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 1;
  transition: opacity 0.3s ease-in-out; /* Added transition property for opacity */

  opacity: 0;
  text-align: center;
}

h1[data-original-text]:hover::after {
  content: ' - Eray Sahin';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 2;
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
  text-align: center;
}

.hover-scale {
  transform: scale(1);
  transition: transform 0.2s ease;
}

.hover-scale:hover {
  transform: scale(1.2);
}
